<template>
  <div
    class="form-input-email form-field"
    :class="{
      'has-content': hasContent || hasFocus,
      'has-error': errors.length > 0,
    }"
  >
    <label class="form-input__label" :for="data.field_key">
      {{
        data.label
          + (data.is_required ? ' *' : '')
      }}
    </label>

    <input
      :id="data.field_key"
      ref="fieldRef"
      :name="data.field_key"
      type="email"
      class="form-input__element"
      @input="
        setValue($event);
        handleEmit($event, data.field_key);
      "
      @focus="setFocus(true)"
      @focusout="setFocus(false)"
    />

    <FormFieldErrors :errors="errors" />
  </div>
</template>

<script setup>
const emit = defineEmits(['emitValue', 'hasErrors']);

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    prefillValue: {
        type: String,
        default: '',
    },
});

const { buildValidationObject } = useFormValidation();

const {
    hasFocus,
    hasContent,
    errors,
    handleEmit,
    setValue,
    setFocus,
} = useFormField(emit, buildValidationObject(props.data));

/*
data to prefill the field with
*/
const fieldRef = ref(null);

onMounted(() => {
    if (props.prefillValue) {
        const { prefillValue } = { ...props };
        fieldRef.value.value = prefillValue;
        setValue(prefillValue);
        handleEmit(prefillValue, props.data.field_key);
    }
});

/*
  data to prefill the field with
*/
watch(() => props.prefillValue, (newValue) => {
    if (newValue === null) {
        fieldRef.value.value = '';
        setValue('');
        handleEmit('', props.data.field_key);
    } else {
        fieldRef.value.value = newValue;
        setValue(newValue);
        handleEmit(newValue);
    }
});
</script>

<style lang="scss" scoped>
.form-input__element {
    @include form-input-element;
}

.form-input__label {
    @include form-input-label;

}

.form-input-email {
    @include form-input-wrapper;
}
</style>
